/** src/http/BaseUrl.js
 * 暴露出请求资源路径的前缀即可 */
const URLENV = {
  SERVER_API: {
    BMDURL: process.env.VUE_APP_BASE_URL,
  },
};
// export default URLENV.DEV
// export default URLENV.PRO
export default URLENV.SERVER_API;

import BaseUrl from "@/http/BaseUrl";

// 2.联系客服
let baseUrlCustomerService =
  "https://chatdev.simu.store/?userId=customer_service&token=";
if (
  process.env.NODE_ENV === "staging" ||
  process.env.NODE_ENV === "development"
) {
  baseUrlCustomerService =
    "https://chatdev.simu.store/?userId=customer_service&token=";
} else if (process.env.NODE_ENV === "production") {
  baseUrlCustomerService =
    "https://chat.simu.store/?userId=customer_service&token=";
}

// 3.IM聊天前缀-非客服
let baseUrlChatService = "https://chatdev.simu.store/?token=";
if (
  process.env.NODE_ENV === "staging" ||
  process.env.NODE_ENV === "development"
) {
  baseUrlChatService = "https://chatdev.simu.store/?token=";
} else if (process.env.NODE_ENV === "production") {
  baseUrlChatService = "https://chat.simu.store/?token=";
}

const envAry = {
  // 白名单（参数不加密）
  whiteList: [
    BaseUrl.BMDURL + "/system/dict/data/type/", // 数据字典
    process.env.VUE_APP_Ali_URL + ".oss-cn-shanghai.aliyuncs.com", // 阿里直传
    BaseUrl.BMDURL + "/home/business/person/curriculum/add", // 用户中心-个人-新增简历
    BaseUrl.BMDURL + "/home/business/person/curriculum/save", // 用户中心-个人-保存简历
    BaseUrl.BMDURL + "/home/inbox/message/connect", // 站内信-手动结束SSE长连接
    BaseUrl.BMDURL + "/home/inbox/message/close", // 站内信-手动结束SSE长连接
    BaseUrl.BMDURL + "/home/business/ZUser/master/analysisInsUrl", // 机构邀请-解析机构邀请加密信息
    // BaseUrl.BMDURL + "/home/business/direct/cal/equity/import", // 用户中心-直营店-股权产品数据管理-excel在线导入数据
    BaseUrl.BMDURL + "/home/business/safe/visitDetail/hiddenProduct", // 安全-访问控制-隐藏基金
  ],

  // 白名单（401出参）
  whiteList401: [
    BaseUrl.BMDURL + "/home/front/roadshow/checkIns", // 机构详情鉴权(其他跳转至机构详情)
  ],

  // 强制退出登录响应值
  logOutCode: [
    11001, 11002, 11003, 11011, 11012, 11013, 11014, 11015, 11016, 10301,
  ],

  // 登录过期响应值
  loginExpiredCode: [11001, 11011, 11012, 11013, 11014, 11015, 11016],

  // 签名非法响应值
  signIllegalCode: [1020, 1021],

  // 越权code
  ultraViresCode: [1022, 1023, 1025, 1026],

  // 2.联系客服
  baseUrlCustomerService,

  // 3.IM聊天前缀-非客服
  baseUrlChatService,

  // 图库尺寸类型
  imgSizeType: {
    cover: "342x249", // 封面
    aboutUs: "342x200", // 关于我们
    banner: "1920x685", // 百科主图
    pvBg: "682x465", // 品宣视频
    saleStore: "342x200", // 直营店
    encyclopedia: "342x249", // 机构百科
    roadshowVideo: "342x200", // 路演视频
    roadshowImg: "342x200", // 路演图片
    roadshowAudio: "342x200", // 路演音频
  },
  imgUpLoadData: {
    // 关于我们
    aboutUs: {
      dir: "institution/us",
      aspectRatio: 1.71,
    },
    // 百科主图
    banner: {
      dir: "institution/wiki",
      aspectRatio: 2.5,
    },
    // 品宣视频
    pvBg: {
      dir: "institution/video",
      aspectRatio: 1.46,
    },
    // 直营店
    saleStore: {
      dir: "direct/image",
      aspectRatio: 1.71,
    },
    // 机构百科
    encyclopedia: {
      dir: "institution/cover",
      aspectRatio: 1.37,
    },
    // 路演视频
    roadshowVideo: {
      dir: "roadshow/video",
      aspectRatio: 1.71,
    },
    // 路演视频
    roadshowVideo: {
      dir: "roadshow/video",
      aspectRatio: 1.71,
    },
    // 路演图文 - 封面
    roadshowImg: {
      dir: "roadshow/image",
      aspectRatio: 1.71,
    },
    // 路演音频
    roadshowAudio: {
      dir: "roadshow/music",
      aspectRatio: 1.71,
    },
  },

  // 机构百科卡片 | 默认图片
  encyclopediaCoverUrl:
    process.env.VUE_APP_Ali_HOST +
    "awards/interview/1706261429000_%E5%9B%BE%E6%80%AA%E5%85%BD_db732e9db2010608fead814d6923e587_90570.png",
};

export default envAry;

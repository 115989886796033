<template>
  <!-- 二级路由占位符 -->
  <router-view />
</template>

<style lang="scss">
@import "@/style/Reset.css";
* {
  list-style: none;
  margin: 0;
  padding: 0;
}
.el-loading-mask {
  z-index: 10;
}

::-webkit-scrollbar {
  //滚动条整体
  width: 10px;
  height: 1px;
}
::-webkit-scrollbar-thumb {
  //滑块部分
  // border-radius: 5px;
  background-color: #d8d8d8;
}
::-webkit-scrollbar-track {
  //轨道部分
  box-shadow: inset 0 0 5px #f1f1f1;
  background: #ededed;
  border-radius: 5px;
}
:root {
  --el-color-primary: #1473e6;
}
</style>

<!-- <style src="@/style/reset.css"  scoped></style> -->

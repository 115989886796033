<!-- cookies 首页 -->
<template>
  <div class="cookies">
    <div
      class="context"
      :style="`left: calc(50% - ${width}px / 2);top: calc(50vh - ${height}px / 2)`"
    >
      <div class="box" :style="`width: ${width}px; height: ${height}px`">
        <!-- 标题 -->
        <div class="title">
          <div class="left">
            <img src="@/assets/logo/logoNew.svg" alt="" />
            <p>欢迎来到私募管家网</p>
          </div>
          <!-- 关闭按钮 -->
          <i @click="alartClose" class="iconfont iconguanbi"></i>
        </div>

        <!-- 中部 -->
        <div class="container">
          <!-- 左导航 -->
          <div class="left-nav">
            <ul>
              <li
                v-for="(item, i) in privacy"
                :key="i"
                @click="privacyShow = i"
                :class="privacyShow == i ? 'active' : ''"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>

          <!-- 右侧内容 -->
          <div class="right-box">
            <div
              v-for="(item, i) in privacy"
              :key="item.title"
              class="right-show-box"
            >
              <div v-show="i == privacyShow">
                <div class="right-box-title">
                  <p>{{ item.title }}</p>
                  <el-switch
                    size="large"
                    v-model="cookiesFrom[item.tag]"
                    style="
                      --el-switch-on-color: #de0011;
                      --el-switch-off-color: #dcdfe6;
                    "
                    active-value="1"
                    inactive-value="0"
                  />
                </div>

                <p>
                  {{ item.content }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- 脚 -->
        <div class="footer">
          <div class="left">
            <i class="iconfont icondianhua-yuankuang"></i>
            <p>400-160-6221</p>
          </div>
          <div class="right">
            <p @click="confirm" class="btn ok">确认我的选择</p>
          </div>
        </div>
      </div>
    </div>

    <div @click="confirm" class="mark"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const width = 642;
const height = 533;

const privacyShow = ref(0);

// 确认选择
const confirm = () => {
  alartClose();
};

// 权限选择
const cookiesFrom = ref({
  must: "1",
  analysis: "1",
  social: "1",
  advertise: "1",
  cookie: "1",
});

// 弹窗关闭
const emit = defineEmits(["alartClose", "submit"]);

const alartClose = () => {
  emit("alartClose", 1);
  localStorage.setItem("cookiesFrom", JSON.stringify(cookiesFrom.value));
};

onMounted(() => {
  if (localStorage.getItem("cookiesFrom")) {
    cookiesFrom.value = JSON.parse(localStorage.getItem("cookiesFrom"));
  }
});

const privacy = [
  {
    title: "您的隐私",
    content:
      "当您访问任何网站时，它可能会在您的浏览器上存储或检索信息，主要是以cookie的形式。这些信息可能与您、您的偏好或您的设备有关，主要用于使网站按您预期工作。这些信息通常不会直接识别您的身份，但它可以为您提供更个性化的网络体验。因为我们尊重您的隐私权，您可以选择不允许某些类型的cookie。单击不同的类别标题以了解更多信息并更改我们的默认设置。然而，阻止某些类型的cookie可能会影响您对网站的体验和我们能够提供的服务。",
    a: "",
    tag: "must",
  },
  {
    title: "必要的Cookie",
    content:
      "这些cookie是网站运行所必需的，不能在我们的系统中关闭。它们通常仅针对您所做的相当于服务请求的行动而设置，例如设置您的隐私偏好、登录或填写表格。您可以将浏览器设置为阻止或提醒您这些cookie，但网站的某些部分将无法正常工作。这些cookie不会存储任何个人身份信息。",
    a: "",
    tag: "analysis",
  },
  {
    title: "目标Cookie",
    content:
      "这些cookie可能由我们的广告合作伙伴通过我们的网站设置。这些公司可能会使用它们来建立您兴趣的个人资料，并向您展示其他网站上的相关广告。它们不直接存储个人信息，而是基于唯一识别您的浏览器和互联网设备。如果您不允许这些cookie，您将体验到不那么有针对性的广告。",
    a: "",
    tag: "social",
  },
  {
    title: "性能 Cookie",
    content:
      "这些cookie允许我们计算访问量和流量来源，以便我们能够衡量和提高网站的性能。它们帮助我们了解哪些页面最受欢迎，哪些页面最不受欢迎，并了解访问者如何在网站上移动。这些cookie收集的所有信息都是汇总的，因此是匿名的。如果您不允许这些cookie，我们将不知道您何时访问了我们的网站，并且无法监控其性能。",
    a: "",
    tag: "advertise",
  },
  {
    title: "功能性 Cookie",
    content:
      "这些cookie使网站能够提供增强的功能和个性化。它们可能由我们或第三方提供商设置，我们已将其服务添加到我们的页面中。如果您不允许这些cookie，那么部分或全部服务可能无法正常运行。",
    a: "",
    tag: "cookie",
  },
];
</script>

<style lang="scss" scoped>
.cookies {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
  font-size: $font-size2;
  color: $title1;
  overflow: auto;
  height: 100%;
  > .context {
    position: absolute;
    z-index: 400;
    // left: calc(50% - 720px / 2);
    > .box {
      // top: calc(50vh - 500px / 2);
      // margin: 120px auto 0;
      width: 642px;
      height: 533px;
      background-color: #fff;
      border-radius: 3px;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      > .title {
        height: 64px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        padding-left: 25px;
        padding-right: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #d8d8d8;
        > .left {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          > i {
            display: block;
            font-size: 26px;
            margin-right: 12px;
          }
          > img {
            display: block;
            width: 26px;
            height: 26px;
            margin-right: 12px;
          }
        }
        > i {
          display: block;
          font-size: 18px;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      > .container {
        display: flex;
        display: -webkit-flex;
        height: calc(100% - 64px - 68px);
        > .left-nav {
          width: 224px;
          height: 100%;
          box-sizing: border-box;
          > ul {
            > li {
              height: 40px;
              box-sizing: border-box;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              -webkit-align-items: center;
              justify-content: center;
              -webkit-justify-content: center;
              font-size: $font-size3;
              border-bottom: 1px solid #d7d7d7;
              border-left: 4px solid transparent;
              background-color: #f4f4f4;
              transition: 0.3s;
              user-select: none;
              cursor: pointer;
            }
            > li.active {
              background-color: #fff;
              border-left-color: $primary-red;
              // border-bottom-color: $title1;
            }
          }
        }
        > .right-box {
          height: 100%;
          padding-top: 38px;
          padding-left: 26px;
          flex: 1;
          -webkit-flex: 1;
          color: $title2;
          padding-right: 30px;
          > .right-show-box {
            flex: 1;
            -webkit-flex: 1;
            > div {
              > .right-box-title {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: space-between;
                -webkit-justify-content: space-between;
                margin-bottom: 20px;
                > p {
                  font-weight: 600;
                }
              }
              > p {
                line-height: 20px;
                font-size: 13px;
              }
            }
          }
        }
      }
      > .footer {
        height: 68px;
        box-sizing: border-box;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        padding-left: 32px;
        padding-right: 23px;
        border-top: 1px solid #d8d8d8;
        > .left {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          > i {
            display: block;
            font-size: 22px;
            margin-right: 10px;
            color: $primary-red;
          }
          > p {
            font-size: 13px;
          }
        }
        > .right {
          user-select: none;
          display: flex;
          display: -webkit-flex;
          > .btn {
            // width: 88px;
            width: 180px;
            height: 32px;
            font-size: 13px;
            box-sizing: border-box;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            justify-content: center;
            -webkit-justify-content: center;
            transition: 0.3s;
            cursor: pointer;
            border: 1px solid $primary-red;
            border-radius: 100px;
            &:hover {
              opacity: 0.8;
            }
          }

          > .btn.ok {
            background-color: $primary-red;
            color: #fff;

            p {
              width: 100%;
              height: 100%;
              @include flex-center;
            }
          }
        }
      }
    }
  }
  > .mark {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(43, 43, 43, 0.7215686275);
    overflow: auto;
    // z-index: 100;
  }
}
</style>

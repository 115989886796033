// 请求封装
// 导出一个对象，该对象拥有get与post方法用于发送相应类型的请求
import axios from "axios";
import { ElNotification, ElMessage } from "element-plus";
import store from "@/store";
import BaseUrl from "@/http/BaseUrl";
import Apis from "@/utils/Apis";
import envAry from "@/envAry";

const instance = axios.create();
// instance.defaults.withCredentials = false;  //不允许跨域携带cookie信息
instance.defaults.timeout = 10 * 1000; // 超时设置

// 是否开启接口加密参数
let axiosEncryption = eval(process.env.VUE_APP_AXIOS_ENCRYPTION);

// 针对instance，添加请求拦截器，每个请求都带着token一起发送
instance.interceptors.request.use(
  (config) => {
    // 判断是否为阿里上传请求 | 其他白名单
    if (
      !config.url ==
        process.env.VUE_APP_Ali_URL + ".oss-cn-shanghai.aliyuncs.com" ||
      config.url.indexOf(envAry.whiteList[0]) != "-1" ||
      envAry.whiteList.indexOf(config.url) == "-1"
    ) {
      // 为接口参数添加加密串
      if (axiosEncryption) {
        if (config.method == "post") {
          config.data = Apis.toNewParams(config.data);
        } else if (config.method == "get" && config.params) {
          config.params = Apis.toNewParams(config.params);
          config.params.sign = encodeURIComponent(config.params.sign);
        }
      }
    }

    // config对象就是请求配置数据对象
    let token = store.state.token || localStorage.getItem("Authorization");
    if (token) {
      config.headers["Authorization"] = token;
    }

    return config;
  },
  (error) => {
    // console.log(error);
    Promise.reject(error);
  }
);

// 针对instance处理统一的业务异常   基于响应拦截器
instance.interceptors.response.use(
  (response) => {
    // console.log(response);
    // 调试用
    let code = response.data.code || 200;

    if (code !== 200) {
      // 统一异常处理 400业务状态码
      if (code == 400) {
        let errmsg = response.data.msg.details[0].message;
        // console.warn("请求参数有误，请检查:", errmsg);
        Notification({
          type: "error",
          title: "注意",
          message: "系统开小差了，等会试试吧！",
        });
      }

      // 强制退出登录
      else if (envAry.logOutCode.indexOf(response.data.code) != "-1") {
        // 退出登录
        Apis.logOut();
      }

      // 401越权
      else if (envAry.whiteList401.indexOf(response.config.url) == "-1") {
        Apis.codeElNotification(response.data.code, response.data.msg);

        ElNotification({
          type: "error",
          title: "错误" + code,
          message: response.data.msg,
          duration: 3000,
        });
      }

      // token过期提示
      else if (envAry.loginExpiredCode.indexOf(response.data.code) != "-1") {
        ElNotification({
          type: "error",
          title: "登录过期",
          message: "登录过期",
          duration: 3000,
        });
      }

      // 接口加密验证
      else if (envAry.signIllegalCode.indexOf(response.data.code) != "-1") {
        ElNotification({
          type: "warning",
          title: "提示",
          message: "签名非法",
          duration: 3000,
        });
      } else {
        ElNotification({
          type: "提示",
          title: "错误" + code,
          message: response.data.msg,
          duration: 3000,
        });
      }
    }

    return response;
  },
  (error) => {
    // console.log("err" + error);

    let { message } = error;

    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }

    ElMessage({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

const myaxios = {
  /**
   * 用于发送 get 请求
   * @param {string} url 请求路径
   * @param {object} params 请求参数对象  {参数名:参数值}
   */
  get(urll, params) {
    return instance({
      // 自定义请求头
      headers: { "content-type": "application/json" },
      url: BaseUrl.BMDURL + urll,
      method: "GET",
      params,
    });
  },

  /**
   * 用于发送 post 请求
   * @param {string} url 请求路径
   * @param {object} data 请求参数对象  {参数名:参数值}
   */
  post(urll, params) {
    return instance({
      headers: { "content-type": "application/json" }, // 自定义请求头
      url: BaseUrl.BMDURL + urll,
      method: "POST",
      data: params,
    });
  },

  /**
   * 用于发送 post 请求,导出文件
   * @param {string} url 请求路径
   * @param {object} data 请求参数对象  {参数名:参数值}
   */
  postBlob(urll, params) {
    return instance({
      headers: { "content-type": "application/json" }, // 自定义请求头
      url: BaseUrl.BMDURL + urll,
      method: "POST",
      data: params,
      responseType: "blob",
    });
  },

  /**
   * 用于发送 get 请求
   * @param {string} url 请求路径
   * @param {object} data 请求参数对象  {参数名:参数值}
   */

  downLoad(urll, params) {
    return instance({
      method: "GET",
      url: BaseUrl.BMDURL + urll,
      params,
      responseType: "blob",
    });
  },

  /**
   * 用于发送 get 请求
   * @param {string} url 请求路径
   * @param {object} data 请求参数对象  {参数名:参数值}
   */

  upLoad(params) {
    return instance({
      headers: { "content-type": "multipart/form-data" }, // 自定义请求头
      method: "POST",
      // url: '/uploadOSS',
      url: process.env.VUE_APP_Ali_URL + ".oss-cn-shanghai.aliyuncs.com",
      data: params,
    });
  },
};

export default myaxios;

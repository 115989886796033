<template>
  <div class="file-download">
    <div class="container">
      <div class="title">
        <h5>文件与下载</h5>
        <div @click="routerGo">
          <p>探索更多</p>
          <i class="iconfont iconxiayibu-01"></i>
        </div>
      </div>
      <div class="context">
        <div class="card" v-for="item in fileList" :key="item.id">
          <div>
            <img
              v-show="item.fileType == 'word'"
              src="@/assets/icon/word.svg"
              alt=""
            />
            <img
              v-show="item.fileType == 'excel'"
              src="@/assets/icon/excel.svg"
              alt=""
            />
            <img
              v-show="item.fileType == 'pdf'"
              src="@/assets/icon/pdf.svg"
              alt=""
            />
          </div>
          <h5>{{ item.fileName }}</h5>
          <h4 @click="checkUser(item.filePath)">
            <p>下载文档</p>
            <i class="iconfont icondownload"></i>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import myaxios from "@/http/Myaxios";
import router from "@/router";
import { onMounted, ref } from "vue";
import { ElMessage } from "element-plus";
import upLoadOssApi from "@/utils/UpLoadApi";
import store from "@/store";

const fileList = ref([]);
const getFileList = () => {
  let url = "/home/index/file/list";

  myaxios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      fileList.value = res.data.data;
    }
  });
};

// 新增下载历史记录
const fileHistoryAdd = (i) => {
  i.signatureFlag = true;
  Apis.fileHistoryAdd(i).then((res) => {
    if (res == 200) {
      downLoadBtn(i);
    }
  });
};

let res = upLoadOssApi.getUserInfo();
let token = store.state.token || localStorage.getItem("Authorization");
// 跳转页面
const routerGo = () => {
  if (token) {
    if (res && res.accountType != "ins" && res.accountType != "person") {
      ElMessage.error("您的账户类型无法访问此页面");
    } else {
      router.push("/homes/FileDownload");
    }
  } else {
    ElMessage({
      message:
        "根据监管规定，私募管家网仅向特定对象提此页面的访问权限，请您点击帐户按钮，注册并完成实名认证以及特定对象确认！",
      type: "warning",
    });
  }
};
// 检查用户
const checkUser = (n) => {
  if (token) {
    if (res && res.accountType != "ins" && res.accountType != "person") {
      ElMessage.error("您的账户类型无法访问此页面");
    } else {
      fileDownload(n);
    }
  } else {
    ElMessage({
      message:
        "根据监管规定，私募管家网仅向特定对象提此页面的访问权限，请您点击帐户按钮，注册并完成实名认证以及特定对象确认！",
      type: "warning",
    });
  }
};

// 下载文件
const fileDownload = (n) => {
  if (n) {
    let url = "/home/common/getURL";
    let params = { urlRequest: n };
    myaxios.post(url, params).then((res) => {
      // console.log(res);
      if (res.data.code == 200) {
        window.open(res.data.data.urlResponse);
      } else {
        ElMessage({
          message: "下载失败，请稍后再试",
          type: "warning",
        });
      }
    });
  } else {
    ElMessage({
      message: "文件地址未找到，请稍后再试",
      type: "warning",
    });
  }
};

onMounted(() => {
  getFileList();
});
</script>

<style lang="scss" scoped>
.file-download {
  color: $color;
  > .container {
    width: 1400px;
    margin: $box-mt auto;
    > .title {
      margin-bottom: 30px;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      align-items: center;
      -webkit-align-items: center;
      > h5 {
        font-size: 22px;
        font-weight: 400;
        color: $title1;
      }
      > div {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        > p {
          cursor: pointer;
          color: $title1;
          &:hover {
            color: $primary-blue;
          }
        }
        > i {
          margin-left: 10px;
          font-size: 25px;
          font-weight: 600;
          color: $primary-red;
        }
      }
    }
    > .context {
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      > .card {
        width: calc((100% - 4 * $card-mr) / 5);
        cursor: pointer;
        height: 309px;
        user-select: none;
        border-radius: 3px;
        padding: 50px 30px 30px 30px;
        margin-right: $card-mr;
        box-shadow: $box-shadow;
        transition: 0.3s;
        box-sizing: border-box;
        position: relative;
        &:hover {
          // transform: translateY(-2px);
          transform: scale(1.25);
        }
        &:last-child {
          margin-right: 0;
        }
        > div {
          margin-bottom: 37px;
          box-sizing: border-box;
          > img {
            display: block;
            width: 51px;
            height: 53px;
          }
        }
        > h5 {
          font-weight: 400;
          font-size: $font-size3;
          line-height: 25px;
          box-sizing: border-box;
          color: $title2;
        }
        > h4 {
          display: flex;
          display: -webkit-flex;
          justify-content: space-between;
          -webkit-justify-content: space-between;
          font-weight: 400;
          box-sizing: border-box;
          position: absolute;
          bottom: 20px;
          width: calc(100% - 30px * 2);

          &:hover {
            color: $primary-blue;
          }
          > p {
            font-size: $font-size3;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
          }
          > i {
            font-size: 25px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="bk">
    <div :class="navBarFixed == true ? 'navBarWrap' : ''" class="container">
      <div class="nav">
        <div class="cente">
          <!-- logo -->
          <div @click="searchShow = 0" class="left-nav">
            <img
              @click="router.push('/home/index')"
              src="@/assets/logo/logoNew.svg"
              alt=""
            />
          </div>

          <!-- 右侧菜单 -->
          <div v-show="searchShow == 0" class="right-nav">
            <!-- 二层 -->
            <div class="floor2">
              <div class="text">
                <div
                  :style="
                    $route.path === '/home/index' ? 'color: #cc2712;' : ''
                  "
                  @click="home"
                >
                  主页
                </div>
                <div
                  :style="orgActive ? 'color: #cc2712;' : ''"
                  @click="navBlock(1)"
                >
                  机构
                </div>
                <div
                  v-if="
                    userInfo.accountType == 'person' ||
                    haveCards == 2 ||
                    haveCards == 3
                  "
                  :style="personActive ? 'color: #cc2712;' : ''"
                  @click="boxShow = 2"
                >
                  个人
                </div>
                <div
                  v-if="
                    userInfo.accountType == 'person' ||
                    userInfo.accountType == 'ins'
                  "
                  :style="
                    $route.path === '/home/Equity' ? 'color: #cc2712;' : ''
                  "
                  @click="boxShow = 3"
                >
                  工具
                </div>
                <div
                  v-if="
                    userInfo.accountType == 'person' ||
                    userInfo.accountType == 'ins'
                  "
                  :style="marketActive ? 'color: #cc2712;' : ''"
                  @click="boxShow = 4"
                >
                  市场
                </div>
                <div
                  :style="
                    $route.path === '/home/SaleStore' ? 'color: #cc2712;' : ''
                  "
                  @click="navBlock(5)"
                >
                  直营店
                </div>

                <div
                  :style="
                    $route.path === '/home/Roadshow' ? 'color: #cc2712;' : ''
                  "
                  @click="navBlock(6)"
                >
                  路演
                </div>
                <div
                  v-if="
                    userInfo.accountType == 'person' ||
                    userInfo.accountType == 'ins'
                  "
                  :style="awardActive ? 'color: #cc2712;' : ''"
                  @click="boxShow = 7"
                >
                  奖项
                </div>
              </div>

              <!-- 搜素 -->
              <div
                v-if="
                  userInfo.accountType == 'person' ||
                  userInfo.accountType == 'ins'
                "
                @click="searchShow = 1"
                class="search-right-box"
              >
                <i class="iconfont iconsousu"></i>
                <p>搜索</p>
              </div>

              <div
                v-show="!logining"
                @click="loginPoint"
                class="search-right-box"
              >
                <i class="iconfont iconzhuce"></i>
                <p>账户</p>
              </div>

              <!-- 登录注册 -->
              <div @click="loginPoint" class="login"></div>

              <!-- 用户头像框 -->
              <div @click="userCenter" v-show="logining" class="user-box">
                <p>{{ userName }}</p>
                <img :src="userHeadSculpture" alt="头像" />
              </div>
            </div>
          </div>
        </div>

        <!-- 底部红色边线 -->
        <p></p>
      </div>

      <!-- 导航下方块 -->
      <div class="nav-box">
        <!-- 每个页签 -->
        <div
          v-for="(item, i) in routerList"
          class="box"
          v-show="boxShow == i + 1"
        >
          <!-- 内容显示 -->
          <div class="context">
            <div class="left">
              <div>
                <!-- 1.标题 -->
                <div class="title">{{ item.title }} →</div>
                <!-- 2.二级导航 -->
                <div>
                  <ul>
                    <li
                      v-for="li in item.pathNavList"
                      @click="routerPoint(li.path)"
                    >
                      {{ li.title }}
                    </li>
                  </ul>
                </div>
                <!-- 3.文字 -->
                <div>
                  <h5>电子邮箱：simu.store@simu.store</h5>
                  <h4>全国客服：400-160-6221</h4>
                  <p>跟随我们：闲画云计算（上海）有限公司</p>
                </div>
                <!-- 4.图标区 -->
                <div class="icon">
                  <img src="@/assets/header/weibo.png" alt="" />
                  <img src="@/assets/header/weChart.png" alt="" />
                  <img src="@/assets/header/email.png" alt="" />
                  <img src="@/assets/header/phone.png" alt="" />
                </div>
              </div>
            </div>
            <div class="middle">
              <div>
                私募管家网隶属于闲画云计算（上海）有限公司为私募全生命周期综合服务平台
              </div>
              <div>
                <h3>{{ item.secondTitle }}</h3>
                <p>
                  {{ item.content }}
                </p>
              </div>
            </div>
            <div class="right">
              <!-- 叉号 -->
              <div>
                <img @click="boxShow = 0" src="@/assets/fork.svg" alt="" />
                <div>指南手册</div>
              </div>
              <ul>
                <li v-for="item in fileUrl" @click="router.push(item.navUrl)">
                  <img src="@/assets/pdf1.png" alt="" />
                  <div>
                    <h5>{{ item.fileTime }}</h5>
                    <p>{{ item.fileTitle }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 阴影遮罩 -->
      <div @mouseover="boxShow = 0" v-show="boxShow != 0" class="mark"></div>

      <!-- 切换搜索框 -->
      <transition name="el-fade-in">
        <div v-show="searchShow == 1" class="search">
          <input
            v-model="navSearch"
            type="text"
            placeholder="请输入关键词"
            @keydown.enter="GlobalSearch"
          />
          <i @click="searchShow = 0" class="iconfont iconcuowu"></i>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import myaxios from "@/http/Myaxios";
import store from "@/store";
import router from "@/router";
import { ref, watch, onMounted, onUnmounted, onBeforeMount } from "vue";
import { ElMessage } from "element-plus";

const emit = defineEmits(["boxShowChange", "clickOutside", "loginPoint"]);
const haveCards = ref(
  store.state.haveCards || localStorage.getItem("haveCards") || 0
); // 0无卡 1个人卡  2机构卡 3办公室卡

// 是否在登录状态（登录按钮与头像框切换）
const logining = ref(false);
const userInfo = ref({
  accountType: null,
});
const userName = ref("");
const userHeadSculpture = ref("");

// 市场激活
const marketActive = ref(false);
// 机构激活
const orgActive = ref(false);
// 个人激活
const personActive = ref(false);
// 奖项激活
const awardActive = ref(false);

// 查询用户基本信息
const getInfo = () => {
  let url = "/home/business/zUser/getInfo";
  myaxios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200 && res.data.data) {
      userInfo.value = res.data.data;
      userName.value = res.data.data.userName;
      userHeadSculpture.value = res.data.data.headSculpture;
      store.commit("lodingSave", {
        userInfo: res.data.data,
        userName: res.data.data.userName,
        headSculpture: res.data.data.headSculpture,
      });
      localStorage.setItem("userInfo", JSON.stringify(res.data.data));
      localStorage.setItem("userName", res.data.data.userName);
      localStorage.setItem("headSculpture", res.data.data.headSculpture);
    }
  });
};

// 导航下方块
const navBlock = (n) => {
  let token = store.state.token || localStorage.getItem("Authorization");

  if (!token) {
    ElMessage({
      message:
        "根据监管规定，私募管家网仅向特定对象提此页面的访问权限，请您点击帐户按钮，注册并完成实名认证以及特定对象确认！",
      type: "warning",
    });
  } else {
    if (
      userInfo.value.accountType == "ins" ||
      userInfo.value.accountType == "person"
    ) {
      boxShow.value = n;
    }
    // 附属账户
    else {
      // 机构
      if (n == 1) {
        router.push("/home/Encyclopedia/private_invest");
      }
      // 直营店
      else if (n == 5) {
        router.push("/home/SaleStore");
      }
      // 路演
      else if (n == 6) {
        router.push("/home/Roadshow");
      }
    }
  }
};

const searchShow = ref(0); // 0搜索隐藏 1搜索显示
watch(
  () => {
    props.isClickOutside;
    router.currentRoute.value.path;
  },
  () => {
    if (props.isClickOutside) {
      searchShow.value = 0;
      emit("clickOutside");
    }

    // 市场
    if (router.currentRoute.value.path.includes("/home/Markets/")) {
      marketActive.value = true;
    } else {
      marketActive.value = false;
    }

    // 机构
    if (router.currentRoute.value.path.includes("/home/Encyclopedia/")) {
      orgActive.value = true;
    } else {
      orgActive.value = false;
    }

    // 个人
    if (router.currentRoute.value.path.includes("/home/Talent")) {
      personActive.value = true;
    } else {
      personActive.value = false;
    }

    // 奖项
    if (router.currentRoute.value.path.includes("/home/Awards")) {
      awardActive.value = true;
    } else {
      awardActive.value = false;
    }
  },
  { immediate: true, deep: true }
);

// 登录注册点击
const loginPoint = () => {
  emit("loginPoint", true);
};

// 用户中心跳转
const userCenter = () => {
  if (
    userInfo.value &&
    (userInfo.value.accountType == "person" ||
      userInfo.value.accountType == "ins")
  ) {
    router.push("/userHome/index");
  } else {
    router.push("/userHome/notifications/notifications-center/index");
  }
};

// 搜索框切换
const isClickOutside = ref(false);
const handler = (e) => {
  if (document.getElementsByClassName("bk")[0].contains(e.target)) {
    isClickOutside.value = false;
  } else {
    isClickOutside.value = true;
  }
  if (isClickOutside.value) {
    //  props.searchShow = 0;
    searchShow.value = 0;
  }
};
onMounted(() => {
  document.addEventListener("click", handler);
});

onBeforeMount(() => {
  let token = store.state.token || localStorage.getItem("Authorization");
  // 判断登录状态
  if (token) {
    getInfo();
    logining.value = true;
  } else {
    // getInfo();
    logining.value = false;
  }

  haveCards.value =
    store.state.haveCards || localStorage.getItem("haveCards") || 0;
});

onUnmounted(() => {
  document.removeEventListener("click", handler);
});

const props = defineProps({
  // 0搜索隐藏 1搜索显示
  searchShow: {
    type: Number,
    default: 0,
  },
  isClickOutside: {
    type: Boolean,
  },
});

// 全局搜索跳转
const GlobalSearch = () => {
  if (navSearch.value.trim()) {
    router.push("/home/GlobalSearch/" + navSearch.value.trim());
  } else {
    router.push("/home/GlobalSearch/null");
  }
};

const boxShow = ref(0); // 0隐藏 1管理人百科  2基金人才库 3私募工具 4在线市场 5直营店 6路演 7基金词典

// 点击主页
const home = () => {
  boxShow.value = 0;
  router.push("/home/index");
};

// 点击二级路由
const routerPoint = (r) => {
  router.push(r);
  boxShow.value = 0;
};
// 导航下方块 | 路由地址
const routerList = [
  {
    title: "管理人百科",
    secondTitle: "让未来商业领袖彼此遇见",
    content:
      "我们始终认为，无论在工作还是生活中，只要坚持原则，就能获得成功。实现愿望的唯一方法就是独立思考并付诸行动。在这里，你不只是加入我们，更会为我们带来精彩新思维。",
    pathNavList: [
      {
        title: "私募证券投资基金管理人",
        path: "/home/Encyclopedia/private_invest",
      },
      {
        title: "私募股权创业投资基金管理人",
        path: "/home/Encyclopedia/private_equity",
      },
      {
        title: "其他私募投资基金管理人",
        path: "/home/Encyclopedia/private_equity",
      },
      {
        title: "资产配置类私募管理人",
        path: "/home/Encyclopedia/private_equity",
      },
    ],
  },
  {
    title: "基金人才库",
    secondTitle: "实现愿望的唯一方法就是独立思考并付诸行动",
    content:
      "私募管家网为闲画云计算（上海）有限公司旗下私募全生命周期综合服务平台，致力于通过提供便捷的基金SaaS工具、云计算数据分析及机器学习算法，为机构客户节约成本，促进相互学习与创新。",
    pathNavList: [
      {
        title: "浏览全部",
        path: "/home/Talent/all",
      },
      {
        title: "高级管理人员",
        path: "/home/Talent/1",
      },
      {
        title: "核心关键雇员",
        path: "/home/Talent/2",
      },
      {
        title: "一般从业人员",
        path: "/home/Talent/3",
      },
    ],
  },
  {
    title: "私募工具",
    secondTitle: "实现愿望的唯一方法就是独立思考并付诸行动",
    content:
      "私募管家网为闲画云计算（上海）有限公司旗下私募全生命周期综合服务平台，致力于通过提供便捷的基金SaaS工具、云计算数据分析及机器学习算法，为机构客户节约成本，促进相互学习与创新。",
    pathNavList: [
      {
        title: "工具箱",
        path: "/home/Equity",
      },
      {
        title: "数据中心",
        path: "/homes/DataCenter",
      },
      {
        title: "合规日历",
        path: "/homes/calendar",
      },
      {
        title: "募问",
        path: "/homes/Question",
      },
      {
        title: "文件与下载",
        path: "/homes/FileDownload",
      },
      {
        title: "网络课程",
        path: "/homes/Course",
      },
      {
        title: "基金词典",
        path: "/homes/FundDictionary",
      },
    ],
  },
  {
    title: "在线市场",
    secondTitle: "新不止于形式，我们应新于时",
    content:
      "想象力比知识更重要，在私募管家网上搜索，或输入一个内容，您想要的触手可及。告诉我们您的问题，我们用数据给您答案。我们做正确的事，即使并不简单。私募管家网，您身边的私募管家。",
    pathNavList: [
      {
        title: "托管外包",
        path: "/home/Markets/index",
      },
      {
        title: "经纪服务",
        path: "/home/Markets/index",
      },
      {
        title: "信息技术",
        path: "/home/Markets/index",
      },
      {
        title: "研究分析",
        path: "/home/Markets/index",
      },
      {
        title: "基金评价",
        path: "/home/Markets/index",
      },
      {
        title: "中介机构",
        path: "/home/Markets/index",
      },
    ],
  },
  {
    title: "直营店",
    secondTitle: "探索新观察，实现真收益",
    content:
      "客户至上最基本的体现是告知客户真相，从客户的根本利益出发并提出公正的建议。虽然我们时常面临着，短期利益与行业竞争，但我们始终坚持这一基本原则。",
    pathNavList: [
      {
        title: "最新入驻",
        path: "/home/SaleStore",
      },
      {
        title: "策略推荐",
        path: "/home/SaleStore",
      },
      {
        title: "获奖机构",
        path: "/home/SaleStore",
      },
    ],
  },
  {
    title: "路演",
    secondTitle: "拉近专业研究和普通认知之间的距离",
    content:
      "未来，从桌面终端到移动应用以及云办公室，我们提供的渠道灵活多样，客户可以随时随地使用我们的数据和分析服务。私募管家网将重新定义私募运营的要素，让相同价值观的企业获得确定性增长。",
    pathNavList: [
      {
        title: "热门路演",
        path: "/home/Roadshow",
      },
      {
        title: "品牌宣传",
        path: "/home/Roadshow",
      },
      {
        title: "基金路演",
        path: "/home/Roadshow",
      },
      {
        title: "投资问答",
        path: "/home/Roadshow",
      },
    ],
  },
  {
    title: "奖项",
    secondTitle: "无论在工作还是生活中，只要坚持原则就能获得成功",
    content:
      " 我的声誉——无论是一直以来的，还是最近被赋予的，似乎全都与“价值”这个概念有关。但是，我事实上真正感兴趣的仅仅是其中用直观而且确凿的方式呈现的那一部分。",
    pathNavList: [
      {
        title: "奖项榜单",
        path: "/home/Awards/index",
      },
      {
        title: "从业人员访谈",
        path: "/home/Awards",
      },
      {
        title: "证券类排名",
        path: "/home/Awards/securities",
      },
      {
        title: "非证券类排名",
        path: "/home/Awards/non-securities",
      },
    ],
  },
];

// 导航下方块 | 右侧文件地址
const fileUrl = ref([
  {
    fileTitle: "私募管家网｜就在您身边",
    fileTime: "2022-02-01",
    navUrl: "/homes/Handbook",
  },
  {
    fileTitle: "私募基金管理人申请登记合规指南",
    fileTime: "2022-02-01",
    navUrl: "/homes/Handbook",
  },
  {
    fileTitle: "私募基金管理人申请产品备案指南",
    fileTime: "2022-02-01",
    navUrl: "/homes/Handbook",
  },
]);

watch(boxShow, (newval) => {
  emit("boxShowChange", newval);
});

// 导航栏固顶
const navBarFixed = ref(false);

// 导航栏搜索
const navSearch = ref("");
</script>

<style lang="scss" scoped>
.bk {
  user-select: none;
  color: $title1;
  > .container {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    position: relative;
    width: 100vw;
    // @include media-query("above") {
    //   // 1920px 及以上
    //   margin: 0 auto;
    //   max-width: $screen-vw;
    // }

    // @include media-query("below") {
    //   // 1920px 以下
    //   width: 100vw;
    // }

    > .nav {
      width: 100%;
      // width: $screen-vw;
      height: 88px;
      transition: 0.3s;
      margin: 0 auto;
      background-color: #f5f5f5;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      box-sizing: border-box;
      padding: 32px 53px;
      z-index: 100;
      box-shadow: -1px 0 9px #898a8b;
      font-size: 20px;
      position: relative;
      user-select: none;
      > .cente {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        flex: 1;
        -webkit-flex: 1;

        > .left-nav {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          width: 50px;
          // logo
          > img {
            width: 100%;
            display: block;
            cursor: pointer;
          }
        }
        > .right-nav {
          margin-left: 20px;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          justify-content: space-between;
          -webkit-justify-content: space-between;
          margin-right: 0;
          flex: 1;
          -webkit-flex: 1;
          padding-right: 0;

          > .floor2 {
            flex: 1;
            -webkit-flex: 1;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            > .text {
              flex: 1 0 auto;
              -webkit-flex: 1 0 auto;
              display: flex;
              display: -webkit-flex;
              > div {
                min-width: 40px;
                flex: none;
                margin-right: 1.6vw;
                border-bottom: 1px solid transparent;
                padding: 7px 20px;
                transition: 0.3s;
                font-size: 19px;
                cursor: pointer;
                &:hover {
                  opacity: 0.8;
                }
                &:last-child {
                  margin-right: 0;
                }
                > i {
                  font-size: 20px;
                }
              }
            }

            > .search-right-box {
              flex: none;
              -webkit-flex: none;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              -webkit-align-items: center;
              margin-right: 37px;
              box-sizing: border-box;
              font-size: 20px;
              cursor: pointer;
              > i {
                font-size: 25px;
              }
              > p {
                margin-left: 10px;
                font-size: 16px;
              }
            }
            > .login {
              flex: none;
              -webkit-flex: none;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              -webkit-align-items: center;
              justify-content: space-between;
              -webkit-justify-content: space-between;

              > div {
                width: 82px;
                height: 34px;
                border: 1px solid #22262a;
                border-radius: 5px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                margin-right: 37px;
                font-size: 12px;
                color: #22262a;
                &:last-child {
                  background-color: #22262a;
                  color: #f7f7f7;
                  margin-right: 0;
                  width: 108px;
                  &:hover {
                    background-color: #f7f7f7;
                    color: #22262a;
                  }
                }
                &:hover {
                  background-color: #22262a;
                  color: #f7f7f7;
                }
              }
            }
            > .user-box {
              margin-left: 10px;
              cursor: pointer;
              user-select: none;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              -webkit-align-items: center;
              margin-right: 5px;

              > p {
                color: #7f7f7f;
                font-size: 14px;
                margin-right: 10px;
              }
              > img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: block;
              }
            }
          }
        }
      }
      > p {
        // width: $screen-vw;
        width: 100vw;
        height: 4px;
        position: absolute;
        z-index: 100;
        bottom: -1px;
        left: 0;
        background-color: $primary-red;
      }
    }
    > .nav-box {
      transition: 0.3s;
      position: absolute;
      z-index: 100;
      top: 88px;
      left: 50%;
      transform: translateX(-50%);
      > .box {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        position: relative;
        > .context {
          display: flex;
          display: -webkit-flex;
          justify-content: center;
          -webkit-justify-content: center;
          width: 1200px;
          min-height: 551px;
          overflow: hidden;
          > .left {
            background-color: #187aba;
            max-width: 372px;
            color: #fff;
            overflow: hidden;
            user-select: none;
            > div {
              padding: 20px 10px 50px 20px;
              width: 100%;
              > .title {
                font-size: 16px;
                border-bottom: 1px solid #fff;
                font-weight: 900;
                padding: 10px 0;
              }
              > .icon {
                margin-top: 50px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                padding-left: 20px;
                > img {
                  width: 36px;
                  margin-right: 30px;
                }
                > i {
                  font-size: 40px;
                  margin-right: 20px;
                }
              }
              > div {
                &:nth-child(2) {
                  border-bottom: 1px solid #4694c7;
                }
                > ul {
                  list-style: none;
                  padding: 0;
                  padding-bottom: 30px;
                  margin-top: 15px;
                  > li {
                    font-weight: 300;
                    padding: 10px 10px;
                    padding-left: 30px;
                    line-height: 25px;
                    font-size: 14px;
                    cursor: pointer;
                    transition: 0.3s;
                    &:hover {
                      background-color: #002b51;
                    }
                  }
                }
                > h5 {
                  margin-top: 20px;
                  font-size: 14px;
                  font-weight: 300;
                  padding: 0 10px;
                  padding-left: 30px;
                  line-height: 30px;
                }
                > h4 {
                  font-size: 14px;
                  font-weight: 300;
                  padding: 0 10px;
                  padding-left: 30px;
                  line-height: 30px;
                }
                > p {
                  font-size: 14px;
                  font-weight: 300;
                  padding: 0 10px;
                  padding-left: 30px;
                  line-height: 30px;
                }
              }
            }
          }
          > .middle {
            flex: 1;
            -webkit-flex: 1;
            background-color: #f7f7f7;
            padding: 10px 50px;
            max-width: 640px;
            min-height: 450px;
            padding-top: 20px;

            > div {
              &:first-child {
                border-bottom: 1px solid #000;
                padding-bottom: 20px;
                line-height: 25px;
                font-size: 14px;
                color: #4c4c4c;
              }
              &:last-child {
                > h3 {
                  color: #333330;
                  font-size: 16px;
                  font-weight: 600;
                  padding: 20px 0;
                }
                > p {
                  color: #2b2b2b;
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 35px;
                }
              }
            }
          }
          > .right {
            background-color: #333333;
            padding: 10px 20px;
            padding-top: 10px;
            padding-right: 30px;
            min-height: 450px;
            max-width: 372px;
            > div {
              position: relative;
              height: 100px;
              // 叉号
              > img {
                position: absolute;
                right: 0;
                top: 30px;
                width: 30px;
                cursor: pointer;
              }
              > div {
                border-top: 1px solid #7f7f7f;
                width: 150px;
                position: absolute;
                bottom: 0;
                color: #ffffff;
                padding-top: 5px;
                font-size: 14px;
                letter-spacing: 2px;
              }
            }
            > ul {
              padding: 0;
              color: #fff;
              box-sizing: border-box;
              margin-top: 50px;
              li {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                cursor: pointer;
                box-sizing: border-box;
                height: 80px;
                > img {
                  width: 40px;
                  height: 40px;
                  display: block;
                  box-sizing: border-box;
                }
                > div {
                  padding-left: 20px;
                  > h5 {
                    font-weight: 300;
                    font-size: 14px;
                    margin-bottom: 5px;
                  }
                  > p {
                    font-weight: 500;
                    font-size: 15px;
                    border-bottom: 1px solid transparent;
                    transition: 0.3s;
                    &:hover {
                      border-bottom: 1px solid #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // 阴影遮罩
    > .mark {
      transition: 0.3s;
      position: absolute;
      z-index: 99;
      width: 200vw;
      height: 1000rem;
      background-color: #2b2b2bb8;
    }

    // 右侧搜索框
    > .search {
      width: $screen-vw;
      position: absolute;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      margin-right: 0;
      width: 100%;
      z-index: 990;
      height: 80px;
      padding: 10px 50px 10px 0;
      > input {
        display: block;
        width: 95%;
        line-height: 80px;
        box-sizing: border-box;
        padding-left: 5px;
        color: #85959e;
        outline: none;
        border: none;
        background-color: #f5f5f5;
        font-size: 16px;
        margin-right: 20px;
      }
      > i {
        cursor: pointer;
        font-size: 30px;
        color: #0f8ec7;
        font-weight: 900;
      }
    }
  }

  // 固顶样式
  .navBarWrap {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100vw;
  }
}

@media (max-width: 600px) {
}
</style>

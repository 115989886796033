import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/iconfont/iconfont.css";
import ElementPlus from "element-plus";
import ViewUIPlus from "view-ui-plus";

import "animate.css";

const app = createApp(App);

// 注册自定义指令
import { directives } from "@/directives/index";
Object.keys(directives).forEach((key) => {
  const directive = directives[key];
  app.directive(key, directive);
});

// 中文文件
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "element-plus/dist/index.css";
import "view-ui-plus/dist/styles/viewuiplus.css";

app
  .use(store)
  .use(router)
  .use(ElementPlus, { locale: zhCn })
  .use(ViewUIPlus)
  .mount("#app");

// app.component(ElCollapseTransition.name, ElCollapseTransition);

import myaxios from "@/http/Myaxios";
import { ElMessage, ElNotification } from "element-plus";
import { aseEncryptParams, aesDecryptParams } from "./Aes";
import store from "@/store";
import router from "@/router";
import envAry from "@/envAry";

const Apis = {
  // 颜色列表
  colorList: [
    {
      label: "蓝色",
      colorStr: "#1473E6",
      lightColor: "#E8F0FE",
      value: "blue",
    },
    { label: "红色", colorStr: "#de0011", lightColor: "#FDEAEA", value: "red" },
    {
      label: "灰色",
      colorStr: "#3E2C2C",
      lightColor: "#E4E4E4",
      value: "grey",
    },
  ],

  /**
   * 身份证判断性别
   * @param {object} res idNumber 18位身份证号码
   * @returns  '1':男  '0'：女
   */
  getGenderByIdNumber(idNumber) {
    return new Promise((resolve, reject) => {
      if (idNumber) {
        let genderCode; // 性别代码
        if (idNumber.length == 18) {
          // 二代身份证号码长度为18位（第17位为性别代码）
          genderCode = idNumber.charAt(16);
        } else if (idNumber.length == 15) {
          // 一代身份证号码长度为15位（第15位为性别代码）
          genderCode = idNumber.charAt(14);
        }
        if (genderCode && !isNaN(genderCode)) {
          // 两代身份证号码的性别代码都为男奇女偶
          if (parseInt(genderCode) % 2 == 0) {
            // sex.value = "女士";
            // return '0'
            resolve("0");
          }
          // sex.value = "先生";
          // return '1'
          resolve("1");
        }
      }
    });
  },

  /**
  * 新增文件下载历史记录
  * @param {object} res {
    fileName: i.fileName, // 文件名
    fileType: i.fileType, // 文件类型
    fileSize: i.fileSize, // 文件大小(MB)
    filePath: i.filePath, // 文件地址
    payFlag: i.payFlag, // 是否付费资源
    signatureFlag: i.signatureFlag, // 是否需要签名
  }
  * @returns {String} '200'  '异常'
  */
  fileHistoryAdd(i) {
    return new Promise((resolve, reject) => {
      let url = "/home/business/person/fileHistory/add";
      let params = {
        fileName: i.fileName, // 文件名
        fileType: i.fileType, // 文件类型
        fileSize: i.fileSize, // 文件大小(MB)
        filePath: i.filePath, // 文件地址
        payFlag: i.payFlag, // 是否付费资源
        signatureFlag: i.signatureFlag || false, // 是否需要签名
      };
      myaxios.post(url, params).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          resolve("200");
        } else {
          resolve("异常");
        }
      });
    });
  },

  /**
   * 登出方法
   */
  logOut() {
    ElNotification({
      type: "warning",
      title: "提示",
      message: "已退出登录，页面即将跳转",
      duration: 3000,
    });

    // 清空登录信息
    store.commit("lodingClean", "");
    localStorage.clear();

    // 清除表单缓存
    if (localStorage.getItem("biographicalNotes")) {
      localStorage.removeItem("biographicalNotes");
    }

    // setTimeout(() => {
    //   location.reload();
    // }, 2100);

    setTimeout(() => {
      router.push("/login/login/index");
    }, 2100);
  },

  /**
   * 为对象添加时间戳+加密串参数
   * Apis.toNewParams(ruleForm)
   * @param {object} res {c:3,e:5,a:7}
   * @returns {object}  {a:7,c:3,e:5,timestamp: 1694504441000,sign: "Sv45UBX0GdcYy7cQNDIHcU0LJSetLg63"}
   */
  toNewParams(ruleForm) {
    let newParam;

    // 有参数传入
    if (ruleForm) {
      // 时间戳
      ruleForm.timestamp = Date.parse(new Date());

      // 排序
      newParam = Apis.objKeySort(ruleForm);

      // 加密
      newParam = Apis.toGetParams(newParam);

      ruleForm.sign = aseEncryptParams(newParam);

      return ruleForm;
    }
    // 无参数传入
    else {
      return;
    }
  },

  /**
   * 将对象按key值 从a-z进行排序
   * @param {object} res {c:3,e:5,a:7}
   * @returns {object}  {a:7,c:3,e:5}
   */
  objKeySort(obj) {
    //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    let newkey = Object.keys(obj).sort();
    let newObj = {}; //创建一个新的对象，用于存放排好序的键值对
    for (let i = 0; i < newkey.length; i++) {
      //遍历newkey数组
      newObj[newkey[i]] = obj[newkey[i]]; //向新创建的对象中按照排好的顺序依次增加键值对
    }

    return newObj; //返回排好序的新对象
  },

  /**
   * 将对象转为GET请求字符串
   * @param {object} res {"name": "111","age":18}
   * @returns {String}  name=111&age=18
   */
  toGetParams(obj) {
    let result = "";
    let item;
    for (item in obj) {
      // if (obj[item] && String(obj[item])) {
      //   result += `&${item}=${obj[item]}`;
      // }
      result += `&${item}=${obj[item]}`;
    }
    if (result) {
      result = "?" + result.slice(1);
    }

    // 去掉第一个'?'
    result = result.substring(1);

    return result;
  },

  /**
   * 根据文件地址判断文件类型
   * @param {String} filePath https://staticdev.simu.store/institution/staff/1693876856650_新建 XLSX 工作表.xlsx
   * @returns {String}  ppt||excel||word||pdf||txt||img
   */
  getFileType(filePath) {
    let type = filePath.split(".").pop();
    let fileType;

    if (type == "pdf") {
      fileType = "pdf";
    } else if (type == "excel" || type == "xlsx") {
      fileType = "excel";
    } else if (type == "docx" || type == "doc") {
      fileType = "word";
    } else if (type == "ppt" || type == "pptx") {
      fileType = "ppt";
    }
    // 文本
    else if (type == "txt") {
      fileType = "txt";
    } else if (type == "jpg" || type == "jpeg" || type == "png") {
      fileType = "img";
    } else {
      fileType = "";
    }

    return fileType;
  },

  /**
   * 请求响应特定处理
   * @param {Number,String} res,msg '2014','你不能访问此机构'
   * Apis.codeElNotification(2014, "错误");
   */
  codeElNotification(c, msg) {
    if (c == 401) {
      ElNotification({
        type: "error",
        title: "注意",
        message: response.data.msg,
      });
      store.commit("updateUser", null);
      // 跳转到登录页面
      router.push("/home/index");
    } else if (c == 1002) {
      ElNotification({
        title: "Error",
        message: "验证码错误",
        type: "error",
      });
    } else if (c == 1003) {
      ElNotification({
        title: "错误",
        message: "该用户已被冻结，联系客服处理",
        type: "warning",
      });
    } else if (c == 1006) {
      ElNotification({
        title: "错误",
        message: "密码错误",
        type: "warning",
      });
    } else if (c == 1004) {
      ElNotification({
        title: "错误",
        message: "用户不存在，请先完成注册",
        type: "warning",
      });
    } else if (c == 1005) {
      ElNotification({
        title: "错误",
        message: "用户名密码不能为空",
        type: "warning",
      });
    }
    // 响应越权特定处理
    else if (envAry.ultraViresCode.includes(c)) {
      ElMessage({
        message: msg,
        type: "warning",
      });

      // 跳转到越权页面
      router.push("/401");
    }
  },

  /**
   * 获取当前日期时间字符串
   * @returns {Object}  {a：2023-07-07 12:00:00,b:2023/07/07 12:00:00,day:2023-07-07,time:12:00:00}
   */
  getNow(time) {
    var date;

    if (time) {
      date = new Date(time);
    } else {
      date = new Date();
    }

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    if (hour < 10) hour = "0" + hour;
    if (minute < 10) minute = "0" + minute;
    if (second < 10) second = "0" + second;

    let timeStr = {
      a:
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second,
      b:
        year +
        "/" +
        month +
        "/" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second,
      day: year + "-" + month + "-" + day,
      time: hour + ":" + minute + ":" + second,
    };

    return timeStr;
  },

  /**
   * 按时间戳为对象数组排序
   * @param {String} res "creatTime"
   * @returns {Array}  排序后的数组
   * arr.sort(sortBy("creatTime"))
   */
  sortBy(field) {
    //根据传过来的字段进行排序
    return (x, y) => {
      return new Date(y[field]) - new Date(x[field]);
    };
  },

  /**
   * 时间戳转字符串
   * @param {String}  1694757766
   * @returns {String}  2023-09-15
   */
  timeFormat(timeObj) {
    let timeStr = timeObj
      .toLocaleString()
      .replace(/\//g, "-")
      .replace(/\b(\d)\b/g, "0$1")
      .split(" ")[0];

    return timeStr;
  },

  /**
   * 获取机构类型
   * @returns {String}  'private_invest'||'private_config'||'private_other' || 'private_equity'
   */
  getCompanyType() {
    return new Promise((resolve, reject) => {
      let url = "/home/business/institution/baseHistory/getMyInsType";

      myaxios.get(url).then((res) => {
        // console.log(res);
        if (res.data.code == 200 && res.data.data) {
          resolve(res.data.data);
        } else {
          resolve("异常");
        }
      });
    });
  },

  /**
   * 跳转客服聊天页面（拼接聊天地址后）
   */
  customerService() {
    let token = store.state.token || localStorage.getItem("Authorization");
    let url = envAry.baseUrlCustomerService + token;

    window.open(url, " _blank");
  },

  /**
   * 上传的图片尺寸校验
   * @param {blob} file: 上传的图片文件
   * @param {number} imgWidth: 图片宽度
   * @param {number} imgHeight: 图片高度
   * @returns {boolean|Promise} 验证结果
   */
  verificationPicPx(file, imgWidth, imgHeight) {
    if (!file || !imgWidth || !imgHeight) return false;
    // 获取文件尺寸，判断尺寸在不在规定范围之内
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (theFile) {
        const image = new Image();
        image.src = theFile.target?.result;
        image.onload = function (res) {
          if (
            res.target.width !== imgWidth ||
            res.target.height !== imgHeight
          ) {
            // console.log("图片尺寸不符合");
            resolve(false);
          } else {
            // console.log("图片尺寸符合");
            resolve(true);
          }
        };
      };
    });
  },

  /**
   * 电子卡权限检查
   * @param {number} type: 需要的权限类型 1: 个人 2: 机构 3: 办公室
   * @returns {boolean} 验证结果
   */
  checkCardsHave(type) {
    let haveCards =
      store.state.haveCards || localStorage.getItem("haveCards") || 0;

    // 个人
    if (type == 1) {
      // 有卡放行
      if (haveCards == 1 || haveCards == 2 || haveCards == 3) {
        return true;
      }
      // 无卡
      else {
        return false;
      }
    }
    // 机构
    else if (type == 2) {
      if (haveCards == 2 || haveCards == 3) {
        return true;
      }
      // 无卡
      else {
        return false;
      }
    }
    // 办公室
    else if (type == 3) {
      if (haveCards == 3) {
        return true;
      }
      // 无卡
      else {
        return false;
      }
    } else {
      return false;
    }
  },

  /**
   * 加密url字符
   * @param {string} 原始字符
   * @returns {string} 加密后字符
   */
  encryptUrl(str) {
    let encryptUrl = encodeURIComponent(aseEncryptParams(str));

    return encryptUrl;
  },
  /**
   * 解密url字符
   * @param {string} 原始字符
   * @returns {string} 还原解密后字符
   */
  decryptUrl(str) {
    let decryptUrl = decodeURIComponent(aesDecryptParams(str));

    return decryptUrl;
  },

  /**
   * 动态设置根元素的字体大小
   * @returns {void}
   * 在移动端，rem单位是动态的，根据屏幕宽度动态设置根元素的字体大小
   */
  setRemUnit() {
    const baseFontSize = 16; // 基础字体大小
    const scale = 10 / baseFontSize; // 每个rem代表的px数量
    const docEl = document.documentElement;
    const resizeEvt =
      "orientationchange" in window ? "orientationchange" : "resize";
    const recalc = function () {
      const clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      docEl.style.fontSize = clientWidth / scale + "px";
    };

    if (!document.addEventListener) return;
    window.addEventListener(resizeEvt, recalc, false);
    document.addEventListener("DOMContentLoaded", recalc, false);
  },

  /**
   * 获取设备类型
   * @returns {String} "mobile" || "pc"
   */
  userDevice() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      return "mobile";
    } else {
      return "pc";
    }
  },
};

export default Apis;
